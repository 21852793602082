/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
  /* Hide the sidebar by default */
  .sidebar {
    display: none;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .sidebar {
    /* position: fixed; */
    min-width: 253px;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Ensure sidebar stays above content */
    background-color: white; /* Change sidebar background color */
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.sidebar-block {
  position: fixed;
  display: block;
  min-width: 253px;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Ensure sidebar stays above content */
  background-color: white; /* Change sidebar background color */
  overflow-y: auto;
  overflow-x: hidden;
}

.item {
  color: #4d4d4d;
}
.item:hover {
  /* background-color: #2c99e3; */
  background-color: #E7E9EB;
  /* color: white; */
  cursor: pointer;
}

/* Default color for SVG */
.svg-icon {
  stroke: #4D4D4D; /* Change this to the default color */
  transition: fill 0.3s ease; /* Add transition for smooth color change */
  /* width: 50px;
  height: 50px; */
}

/* Change color on hover */
.svg-icon:hover {
  stroke: white; /* Change this to the hover color */
}
